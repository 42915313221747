<div class="card-container">
  <div class="card">
    <h1>{{ roomName }}</h1>
    <p>Total Grids: {{ totalGrids }}</p>
    <button (click)="copyRoomLink()" data-test="copy-room-link-button">Copy Room Link</button>
    <button (click)="exitRoom()" data-test="exit-room-button">Exit Room</button>
  </div>
  <div class="card">
    <h1>Add New Grid</h1>
    <div class="grid-name-container">
      <label>
        Grid Name:
        <input matInput type="text" [(ngModel)]="newGridName" placeholder="Enter grid name" maxlength="30" style="width: 30ch;" data-test="new-grid-name-input">
      </label>
    </div>
    <div class="input-container">
      <label>
        Rows:
        <input matInput type="number" [(ngModel)]="newGridRows" min="1" placeholder="Enter number of rows" data-test="new-grid-rows-input">
      </label>
      <label>
        Columns:
        <input matInput type="number" [(ngModel)]="newGridCols" min="1" placeholder="Enter number of columns" data-test="new-grid-cols-input">
      </label>
    </div>
    <button (click)="saveNewGrid()" data-test="save-new-grid-button">Save New Grid</button>
  </div>
</div>
<br>
<app-grid-list></app-grid-list>
