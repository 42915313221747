<!-- Top Row -->
<div class="flex-row flex-wrap">
  <!-- Left Side: Control Dropdown Room Info -->
  <div class="column">
    @if (this.gridPackage.roomID !== null && this.gridPackage.roomID !== '') {
      <!-- Room Information -->
      <div>
        <mat-card>
          <h1 style="font-size: 2rem; margin: auto; margin-top: 2rem; text-align: center;">{{this.gridPackage.name}}</h1>
          <p style="font-size: 1rem; margin: auto; margin-top: 1.2rem">Last Saved:</p>
          <p style="font-size: 1rem; margin: auto;">{{this.gridPackage.lastSaved | date:'short'}}</p>
          <div class="grid-buttons">
            <button (click)="copyGridLink()">Copy Grid Link</button>
            <button (click)="leaveGrid()">Leave Grid</button>
          </div>
        </mat-card>
      </div>
      <br/>
    } @else {
      <div>
        <mat-card>
          <h1 style="font-size: 2rem; margin: auto; margin-top: 2rem; text-align: center;">Playground Grid</h1>
          <!-- <p style="font-size: 1rem; margin: auto; margin-top: 1.2rem">Last Saved:</p> -->
          <p style="font-size: 1rem; margin: auto; margin-top: 1.2rem; margin-bottom: 1.2rem;">This Grid Cannot be saved</p>
        </mat-card>
      </div>
      <br/>
    }
    <!-- Control Instructions Drop down panel -->
    <div>
      <mat-card>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header class="pannel-text">
            <mat-panel-description >
              Controls Key <mat-icon style="margin-left: auto"> tips_and_updates </mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            <strong>Clicking</strong> on a cell in the grid will focus onto that cell, allowing you to type.
          </p>
          <p> <strong>Typing</strong> when clicked into the grid will fill the focused cell with the typed character and move across the grid in the specified typing direction.
            (Default direction is right)</p>
          <p>
            <strong>Backspace</strong> deletes the current cell value and moves to the left.
            If "Delete Direction Linked To Type Direction" is true, it will move opposite the typing direction instead.
          </p>
          <p>
            <strong>Ctrl + Backspace</strong> deletes current cell value without moving.
          </p>
          <p>
            <strong>Arrow Keys</strong> navigate through the grid in the respective direction.
          </p>
          <p>
            <strong>Ctrl + Arrow Keys</strong> bold the edge in the respective direction. If all edges are bolded on a given cell the
            cell background goes black, and you cannot highlight it.
          </p>
          <p>
            <strong>Ctrl + Left Click</strong> will bold all edges, and black out the cell background.
          </p>
          <p>
            <strong>Alt + Left Click</strong> on a blacked-out cell will turn the cell white, while keeping edges bold.
            This also means you can highlight the cell.
          </p>
          <p>
            <strong>Right Click</strong> will highlight the cell background to the current selected color.
            You can unhighlight the cell by <strong>Right-Clicking</strong> Again with the same color as the cell selected.
          </p>
          <p>
            <strong>Holding Shift and Dragging</strong> your mouse out of the current cell will highlight it.
            This is to highlight multiple cells at once.
          </p>
          <p>
            The <strong>Height Input</strong> makes the grid shorter/taller.
            The <strong>Width Input</strong> makes the grid thinner/thicker.
          </p>
          <p>
            The <strong>Size Input</strong> makes the cells themselves larger/smaller.
          </p>
        </mat-expansion-panel>
      </mat-card>
    </div>
  </div>
  <!-- Right Side: Control Inputs -->
  <div class="flex-row flex-wrap column">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Controls</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="flex-row flex-wrap">
          <!-- height and width -->
          <div class="control-group">
            <p>Height</p>
            <input matInput [(ngModel)]="gridHeight" type="number" (input)="onSizeInput()"
              placeholder="{{ gridHeight }}" />
            <p>Width</p>
            <input matInput [(ngModel)]="gridWidth" type="number" (input)="onSizeInput()"
              placeholder="{{ gridWidth }}" />
          </div>
          <!-- Cell Size -->
          <div class="control-group">
            <p>Cell Size</p>
            <input matInput [(ngModel)]="cellSize" type="number" placeholder="{{ cellSize }}" />
          </div>
        </div>
        <!-- Highlight Colors -->
        <div>
          <br />
          Highlight color: {{ currentColor }}
          <br />
          <mat-button-toggle-group appearance="legacy" class="highlight-button-group" [(ngModel)]="currentColor">
            @for (highlight of highlight; track highlight) {
            <mat-button-toggle class="highlight-button" [value]="highlight">{{ highlight }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        </div>
        <!-- Typing Direction -->
        <div class="control-group">
          <button mat-button (click)="cycleTypingDirection()">
            Cycle Typing Direction
          </button>
          <p class="status-text">Current Typing Direction:</p>
          <p class="status-text">{{ typeDirection | uppercase }}</p>

        </div>
        <!-- Delete Direction Toggle -->
        <div class="control-group">
          <button mat-button (click)="deleteDirectionToggle()">
            Toggle Linked Delete Direction
          </button>
          <p class="status-text">Delete Direction Linked To Type Direction: </p>
          <p class="status-text">{{ deleteDirectionBool ? 'ON' : 'OFF' }}</p>
        </div>
        @if (this.gridPackage.roomID !== null){
        <div class="control-group">
          <button class="save-button" data-test="saveGridButton" (click)="saveGrid()">
            Save Grid
          </button>
        </div>
        }
      </mat-card-content>
    </mat-card>
  </div>
</div>
<!-- Grid -->
<div class="flex-row">
  <div [ngStyle]="{
      height: gridHeight * cellSize + 'px',
      width: gridWidth * cellSize + 'px'
    }">
    <mat-grid-list cols="{{ gridWidth }}" gridHeight="1:1" gutterSize="0">
      @for (row of gridPackage.grid; track row; let rowIndex = $index) { @for
      (cell of row; track cell; let colIndex = $index) {
      <mat-grid-tile Rowspan="1" Colspan="1">
        <app-grid-cell tabindex="0" [gridCell]="gridPackage.grid[rowIndex][colIndex]"
          (keydown)="onKeydown($event, currentCol, currentRow)" (click)="onClick($event, colIndex, rowIndex)"
          [attr.row]="rowIndex" [attr.col]="colIndex" [row]="rowIndex" [col]="colIndex" [grid]="gridPackage.grid"
          [currentColor]="currentColor" [ngStyle]="{ height: cellSize + 'px', width: cellSize + 'px' }"
          (gridChange)="onGridChange($event)"></app-grid-cell>
      </mat-grid-tile>
      } }
    </mat-grid-list>
  </div>
</div>
