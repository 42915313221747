<div class="flex-row flex-wrap">
  <!-- Search Param Entry Box -->
  <div class="flex-row flex-wrap column left">
    <mat-card class="search-card">
      <mat-card-header>
        <mat-card-title class="word-list-title"
          >Anagram Generator</mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <!-- Filtering is done in Mongo/server side -->
        <div class="flex-row gap-8 flex-wrap">
          <mat-form-field class="input-field">
            <mat-label>Input letters</mat-label>
            <input
              matInput
              data-test="wordContainsInput"
              placeholder="Filter by contents"
              [(ngModel)]="contains"
            />
          </mat-form-field>

          <mat-form-field class="input-field">
            <mat-label>Word Group</mat-label>
            <input
              matInput
              data-test="wordGroupInput"
              placeholder="Filter by wordGroup"
              [(ngModel)]="group"
            />
          </mat-form-field>
          <mat-form-field class="imput-field">
            <mat-label>Input length</mat-label>
            <input matInput data-test="wordLengthInput" type="number" placeholder="Filter by length" [(ngModel)]="length">
          </mat-form-field>
        </div>
        <div>
          <label for="filterType">Filter Type</label>
          <mat-radio-group
            [(ngModel)]="filterType"
            aria-label="Filter Type"
            dataTest="filterTypeRadio"
          >
            <mat-radio-button checked value="exact">Exact</mat-radio-button>
            <mat-radio-button value="contains">Contains</mat-radio-button>
          </mat-radio-group>
        </div>
        <!-- Sorting is done on the client -->
        <mat-form-field class="input-field">
          <mat-label>Sort</mat-label>
          <mat-select [(ngModel)]="sortType" data-test="sortTypeSelect">
            <mat-option value="null">--</mat-option>
            <mat-option value="alphabetical">Alphabetical</mat-option>
            <mat-option value="length">Length</mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <label for="sortOrder">Sort Order</label>
          <mat-radio-group
            aria-label="Sort Order"
            [(ngModel)]="sortByWordOrGroup"
            data-test="sortOrderRadio"
          >
            <mat-radio-button checked value="word">word</mat-radio-button>
            <mat-radio-button value="wordGroup">wordGroup</mat-radio-button>
          </mat-radio-group>
          <p>
            <mat-slide-toggle
              class="sortOrder"
              [(ngModel)]="sortOrder"
              data-test="sortOrderToggle"
              aria-label="Change sort order"
            >
              Change Sort Order
            </mat-slide-toggle>
          </p>
        </div>
        <button
          mat-fab
          class="add-word-fab"
          matTooltip="Add New Word Group"
          matTooltipPosition="right"
          routerLink="/anagram/new"
          data-test="addWordButton"
        >
          <mat-icon class="md-24" aria-label="Add Word Group">add</mat-icon>
        </button>
        <button
        mat-fab
        class="delete-wordGroup-fab"
        matTooltip="Delete Word Group"
        matTooltipPosition="right"
        (click)="deleteWordGroup(group())"
        routerLink="/anagram"
        data-test="deleteWordGroupButton"
      >
        <mat-icon class="md-24" aria-label="Delete Word Group">delete</mat-icon>
      </button>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- Word List Links -->
  <div class="flex-row column right">
    <mat-card>
      <mat-card-header>Word Lists</mat-card-header>
      <mat-card-subtitle style="visibility: hidden"
        >Groups from room :
      </mat-card-subtitle>
      <mat-card-content>
        @for (group of this.wordGroups; track group) {
        <div>
          <button
            routerLink="/anagram/wordGroup/{{ group }}"
            data-test="wordGroupProfileButton"
          >
            {{ group }}
          </button>
        </div>
        }
      </mat-card-content>
    </mat-card>
  </div>
</div>
<br />
<div class="flex-row flex-wrap">
  <!-- Words From Server -->
  <div class="flex-row column left">
    @if(serverFilteredContext()) {
    <div class="flex-1">
      <mat-card>
        <mat-card-content>
          <mat-nav-list class="anagram-nav-list">
            @for (word of displayWords(); track word._id) {
            <div class="anagram-list-item">
              <mat-expansion-panel
                data-cy="expansion-panel-header"
                class="word-list-expansion-panel"
                (opened)="(true)"
                (closed)="(false)"
                matTooltipPosition="right"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title class="anagram-list-word">
                    {{ word.word }}
                  </mat-panel-title>
                  <mat-panel-description class="anagram-list-word-group">{{
                    word.wordGroup
                  }}</mat-panel-description>
                </mat-expansion-panel-header>
                <button
                  mat-fab
                  class="delete-word-fab"
                  matToolTips="Delete Word"
                  matTooltipPosition="right"
                  (click)="deleteWord(word._id)"
                  routerLink="/anagram"
                  data-test="deleteWordButton"
                >
                  <mat-icon class="md-24" aria-label="Delete Word"
                    >delete</mat-icon
                  >
                </button>
              </mat-expansion-panel>
            </div>
            }
          </mat-nav-list>
          @if(displayWords().length === 0) {
          <p>No words match your search parameters</p>
          }
        </mat-card-content>
        <mat-card-actions>
          <mat-paginator
          [length]="getNumWords()"
          [pageSize]="25"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="handlePageEvent($event, 'word list paginator')"
          aria-label="Select page"
          data-test="word-list-mat-paginator"
        >
        </mat-paginator>
        </mat-card-actions>
      </mat-card>
    </div>
    } @else {
    <div class="flex-1" class="anagram-error">
      <mat-error>
        There was a problem loading the words. Possibly the server is down or
        perhaps there are network issues.
      </mat-error>
      <mat-error>
        Please wait a bit and try again or start the server.
      </mat-error>
    </div>
    }
  </div>
  <br />
  <!-- Search History -->
  <div class="flex-row column right">
    @if(serverFilteredContext()){
    <div class="flex-1">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Search History</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list class="anagram-nav-list-history">
            @if(searchHistory().length === 0){
            <p>No Saved Searches</p>
            } @for(search of searchHistory().slice(max(searchHistory().length -
            40, 0),searchHistory().length).reverse(); track search._id) {
            <div class="anagram-list-item">
              <button (click)="updateParams(search.contains, search.wordGroup)" class="search-history-button">
                @if(search.contains !== null){
                  <span matListItemLine class="anagram-search-history-contains"
                    >Contains: "{{ search.contains }}"</span
                  >
                  } @if(search.wordGroup !== null){
                  <span matListItemLine class="anagram-search-history-wordGroup"
                    >From word group: "{{ search.wordGroup }}"</span
                  >
                  }
                </button>
              <br />
            </div>
            }
          </mat-list>
        </mat-card-content>
        <mat-card-actions>
          <mat-paginator
          [length]="getNumSearches()"
          [pageSize]="25"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="handlePageEvent($event, 'search history paginator')"
          aria-label="Select page"
          data-test="search-history-mat-paginator"
        >
        </mat-paginator>
        </mat-card-actions>
      </mat-card>
    </div>
    } @else {
    <p>Something went wrong with getting your search history, Apologies!</p>
    }
  </div>
</div>
